@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.icon-carousel {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.icon-list {
  display: flex;
  animation: scroll 15s linear infinite;
  width: 200%;
}

.icon-list:hover {
  animation-play-state: paused; 
}

.icon-item {
  flex: none;
  margin-right: 1rem; 
  max-width: 50px;
}
